import React from 'react'
import TCDD from '../../../assets/images/tcdd.png';
import TMS from '../../../assets/images/tms.png';
import Siemens from '../../../assets/images/siemens.png';
import Holysis from '../../../assets/images/holysis.png';
import Turasas from '../../../assets/images/turasas.png';
import RoweHankins from '../../../assets/images/rowe-hankins.png';
function OurReferences() {
  return (
    <div className="references">
      <div>
        <img src={TMS} alt='Tms' title='Tms' />
      </div>
      <div>
        <img src={TCDD} alt='TCDD' title='TCDD' />
      </div>
      <div>
        <img src={Siemens} alt='Siemens' title='Siemens' />
      </div>
      <div>
        <img src={Turasas} alt='Türasaş' title='Türasaş' />
      </div>
      <div>
        <img src={Holysis} alt='Holysis' title='Holysis' />
      </div>
      <div>
        <img src={RoweHankins} alt='RoweHankins' title='RoweHankins' />
      </div>
    </div>
  )
}
export default OurReferences;