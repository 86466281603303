import { Route, Routes, useLocation } from "react-router-dom";
import AboutUs from "./Components/AboutUs/AboutUs";
import Career from "./Components/Career/Career";
import Footer from "./Components/Common/Footer/Footer";
import Navbar from "./Components/Common/Navbar";
import Contact from "./Components/Contact/Contact";
import HomePage from "./Components/HomePage/HomePage";
import OurServices from "./Components/OurServices/OurServices";
import { AnimatePresence } from "framer-motion";

function App() {
  const location = useLocation();
  return (
    <>
      <Navbar />

      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/our-services" element={<OurServices />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </AnimatePresence>

      <Footer />
    </>
  );
}

export default App;
