import React from 'react'

function PagesTop( {header} ) {
  return (
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
        <div className="carousel-caption d-none d-md-block" >
          <h4>{header}</h4>
        </div>
      </div>
    </div>
  </div>
  )
}
export default PagesTop;