import React from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Languages } from '../../Languages/Languages';
import { selectLanguageId } from '../../store/selectors';
import PagesTop from '../Common/PagesTop/PagesTop';
import CommonPart from './CommonPart';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';


function AboutUs(props) {
  const { aboutUs, aboutUsParts } = Languages[props.languageId];
  return (
    <>
      {
        props.languageId === "tr" ?
          <Helmet>
            <title>Planck Signal | Hakkımızda</title>
            <meta
              name="description"
              content="Planck Signal | Planck Signal Ekibi olarak projelerimizi görmek ve Planck Signal ekibini tanımak için hemen sitemizi ziyaret edin | Planck Signal"
              data-react-helmet="true"
            />

            <meta name="keywords" content="Hakkında Planck, Sinyalizasyon" data-react-helmet="true" />
          </Helmet>
          :
          <Helmet>
            <title>Planck Signal | About Us</title>
            <meta
              name="description"
              content="Planck Signal | As Planck Signal Team, visit our website now to see our projects and to get to know Planck Signal team | Planck Signal"
              data-react-helmet="true"
            />

            <meta name="keywords" content="About Planck, Signalling" data-react-helmet="true" />
          </Helmet>
      }

      <motion.div className='about-us'
        initial={{ width: 0 }}
        animate={{ width: "100%"}}
        exit={{ x: window.innerWidth, transition: { duration: 0.2} }}>
        <PagesTop header={aboutUs} />
        {
          aboutUsParts.length !== 0 ? aboutUsParts.map(aUs => (
            <React.Fragment key={aUs.id}>
              <CommonPart header={aUs.name} content={aUs.content} backgroundC={aUs.background} iconClass={aUs.iconClass} />
            </React.Fragment>
          )) : null
        }
      </motion.div>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  languageId: selectLanguageId(),
});

export default connect(mapStateToProps)(AboutUs);
