import React from 'react';
import Background from '../../../assets/images/aboutus.jpg';
import { Languages } from '../../../Languages/Languages';

function ServicesSlider(props) {

  const { sliderServices } = Languages[props.languageId]

  return (
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={Background} className="d-block" alt="Planck Signal" title='Planck Signal' />
          <div className="carousel-caption d-none d-md-block" >
            <h4>{sliderServices[0].header}</h4>
            <p>{sliderServices[0].content}</p>
          </div>
        </div>

        {
          sliderServices.filter(item => item.id !== 0).map(slider => (
            <React.Fragment key={slider.id}>

              <div className="carousel-item">
                <img src={Background} className="d-block" alt="Planck Signal" title='Planck Signal' />
                <div className="carousel-caption d-none d-md-block">
                  <h4>{slider.header}</h4>
                  <p>{slider.content}</p>
                </div>
              </div>

            </React.Fragment>
          ))
        }
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
};



export default ServicesSlider;