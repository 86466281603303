import React from 'react'
import { Link } from 'react-router-dom';
import PlanckSignalAboutUs from '../../../assets/images/signalling-logo.png';
import { Languages } from '../../../Languages/Languages';

function AboutUs(props) {

    const { aboutUsHeader, aboutUsShortExplaining, more } = Languages[props.languageId]

    return (
        <div className='about-us-homepage'>
            <div>
                <img src={PlanckSignalAboutUs} alt="Planck Signal Hakkımızda" title='Planck Signal Hakkımızda' />
            </div>

            <div>
                <h2>
                    {aboutUsHeader}
                </h2>
                <p>
                   {aboutUsShortExplaining}<Link to="/about-us" className='ms-2'>{more}...</Link>
                </p>
            </div>
        </div>
    )
}



export default AboutUs;