import React from 'react'

function ContactPart(props) {
    return (
        <div className='contact-part' style={{background:props.background}}>
            <i className={props.iconClass} ></i>
            <h5>{props.header}</h5>
            {props.children}
        </div>
    )
}
export default ContactPart;