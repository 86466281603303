import React from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PagesTop from '../Common/PagesTop/PagesTop';
import ContactPart from './ContactPart';
import ContactUs from './ContactUs';
import { selectLanguageId } from '../../store/selectors';
import { Languages } from '../../Languages/Languages';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

function Contact(props) {

  const { contact, mainAddressContactHeader, phoneNumberContactHeader, socialMediaContactHeader, mailContactHeader,
    nameInput, surnameInput, mailInput, subjectInput, messageInput, contactUsHeader, sendButton } = Languages[props.languageId];

  return (
    <>
      {
        props.languageId === "tr" ?
          <Helmet>
            <title>Planck Signal | İletişim</title>
            <meta
              name="description"
              content="Planck Signal | Planck Signal Ekibi olarak projelerimizi görmek ve Planck Signal ekibini tanımak için hemen sitemizi ziyaret edin | Planck Signal"
              data-react-helmet="true"
            />

            <meta name="keywords" content="Planck Signal, Sinyalizasyon" data-react-helmet="true" />
          </Helmet>
          :
          <Helmet>
            <title>Planck Signal | Contact</title>
            <meta
              name="description"
              content="Planck Signal | As Planck Signal Team, visit our website now to see our projects and to get to know Planck Signal team | Planck Signal"
              data-react-helmet="true"
            />

            <meta name="keywords" content="Planck Signal, Signalling" data-react-helmet="true" />
          </Helmet>
      }
      <motion.div className='contact'
       initial={{ width: 0 }}
       animate={{ width: "100%"}}
       exit={{ x: window.innerWidth, transition: { duration: 0.2} }}>
        <PagesTop header={contact} />

        <div className='contact-body'>

          <ContactPart header={mainAddressContactHeader} iconclassName="fas fa-map-marker-alt" background="linear-gradient(#f03477, #b1496e)" >
            <p>Etimesgut YHT Araç Bakım Atölye Müdürlüğü Ayaş Yolu Bulvarı, Etiler Mah. No:89/C
              Ankara - TURKEY</p>
          </ContactPart>

          <ContactPart header={phoneNumberContactHeader} iconclassName="fas fa-phone" background="linear-gradient(#555c66, #292d33)" >
            <a href="tel:05546762913">0554 676 2913</a>
            <br />
            <a href="tel:05545294374">0554 529 4374</a>
          </ContactPart>

          <ContactPart header={mailContactHeader} iconclassName="fas fa-envelope" background="linear-gradient(#f03477, #b1496e)" >
            <a href="mailto:info@plancksignal.com">info@plancksignal.com</a>
          </ContactPart>

          <ContactPart header={socialMediaContactHeader} iconclassName="fas fa-hashtag" background="linear-gradient(#555c66, #292d33)" >
            <a href="#">LinkedIn</a>
            <br />
            <a href="#">Instagram</a>
          </ContactPart >
        </div>
        <ContactUs nameInput={nameInput} surnameInput={surnameInput} mailInput={mailInput} subjectInput={subjectInput}
          messageInput={messageInput} contactUsHeader={contactUsHeader} sendButton={sendButton} />
      </motion.div>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  languageId: selectLanguageId(),
});

export default connect(mapStateToProps)(Contact);