import axios from 'axios';
import React, { useRef } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Languages } from '../../Languages/Languages';
import { selectLanguageId } from '../../store/selectors';
import PagesTop from '../Common/PagesTop/PagesTop';
import { Helmet } from 'react-helmet';
import {motion} from 'framer-motion';

function Career(props) {
  const { career, nameInput, surnameInput, phone, mailInput, gender, man, woman, address, sendButton } = Languages[props.languageId];
  const name = useRef();
  const surname = useRef();
  const phoneInput = useRef();
  const eMail = useRef();
  const linkedin = useRef();
  const genderSelect = useRef();
  const addressInput = useRef();

  const onClickSend = (e) => {
    e.preventDefault();

    axios.post("http://localhost:5000/send-mail/career", {
      name: name.current.value,
      surname: surname.current.value,
      eMail: eMail.current.value,
      phoneInput: phoneInput.current.value,
      linkedin: linkedin.current.value,
      genderSelect: genderSelect.current.value,
      addressInput: addressInput.current.value,
    }).then(() => {
      name.current.value = "";
      surname.current.value = "";
      eMail.current.value = "";
      phoneInput.current.value = "";
      linkedin.current.value = "";
      genderSelect.current.value = "";
      addressInput.current.value = "";
    });
  }

  return (
    <>
      {
        props.languageId === "tr" ?
          <Helmet>
            <title>Planck Signal | Kariyer</title>
            <meta
              name="description"
              content="Planck Signal | Planck Signal Ekibi olarak projelerimizi görmek ve Planck Signal ekibini tanımak için hemen sitemizi ziyaret edin | Planck Signal"
              data-react-helmet="true"
            />

            <meta name="keywords" content="Planck Signal, Sinyalizasyon" data-react-helmet="true" />
          </Helmet>
          :
          <Helmet>
            <title>Planck Signal | Career</title>
            <meta
              name="description"
              content="Planck Signal | As Planck Signal Team, visit our website now to see our projects and to get to know Planck Signal team | Planck Signal"
              data-react-helmet="true"
            />

            <meta name="keywords" content="Planck Signal, Signalling" data-react-helmet="true" />
          </Helmet>
      }
      <motion.div className='career'
      initial={{ width: 0 }}
      animate={{ width: "100%"}}
      exit={{ x: window.innerWidth, transition: { duration: 0.2}}}
      >
        <PagesTop header={career} />

        <div className='career-body'>
          <form onSubmit={onClickSend}>
            <div>
              <input type="text" ref={name} name='name' placeholder={nameInput} required />
              <input type="text" ref={surname} name='surname' placeholder={surnameInput} required />

              <input type="text" ref={phoneInput} name='phone' placeholder={phone} required />
              <input type="email" ref={eMail} name='email' placeholder={mailInput} required />

              <input type="text" ref={linkedin} name='linkedin' placeholder='LinkedIn' />

              <select ref={genderSelect}>
                <option value="">{gender}</option>
                <option value="man">{man}</option>
                <option value="woman">{woman}</option>
              </select>

              <textarea ref={addressInput} name='address' rows="4" placeholder={address} />

            </div>

            <div>
              <button>{sendButton}</button>
            </div>

          </form>
        </div>
      </motion.div>
    </>
  )
};

const mapStateToProps = createStructuredSelector({
  languageId: selectLanguageId()
});

export default connect(mapStateToProps)(Career);