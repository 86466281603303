import React from 'react'

function CommonPart({ header, content, backgroundC, iconClass }) {
    return (
        <div className='about-us-common-part mx-auto my-4' style={{ background: `${backgroundC}` }}>
            <div>
                <i className={iconClass}></i>
            </div>
            <div>
                <h3>
                    <b>
                        {header}
                    </b>
                </h3>
                <p>
                    {content}
                </p>
            </div>
        </div>
    )
}
export default CommonPart;