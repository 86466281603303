import axios from 'axios';
import React, { useRef } from 'react'

function ContactUs(props) {
    const name = useRef();
    const surname = useRef();
    const email = useRef();
    const subject = useRef();
    const message = useRef();

    const { nameInput, surnameInput, mailInput, subjectInput, messageInput, contactUsHeader, sendButton } = props;

    const onClickSend = e => {
        e.preventDefault();

        axios.post("http://localhost:5000/send-mail/contact-us", {
            name: name.current.value,
            surname: surname.current.value,
            email: email.current.value,
            subject: subject.current.value,
            message: message.current.value
        }).then(() => {
            name.current.value = "";
            surname.current.value = "";
            subject.current.value = "";
            email.current.value = "";
            message.current.value = "";

        });

    }

    return (
        <div className='contact-us-absolute'>
            <p>{contactUsHeader}</p>

            <form onSubmit={onClickSend}>
                <div className='input-part-flex'>
                    <input type="text" name='name' ref={name} placeholder={nameInput} />
                    <input type="text" name='surname' ref={surname} placeholder={surnameInput} />
                </div>
                <input type="text" name='subject' ref={subject} placeholder={subjectInput} />
                <input type="email" name='email' ref={email} placeholder={mailInput} />
                <textarea className='w-100' name='message' rows="6" ref={message} placeholder={messageInput} />
                <div className='w-100 text-end'>
                    <button>{sendButton}</button>
                </div>
            </form>

        </div>
    )
}
export default ContactUs;