import React from 'react'

function Service({ header, content, imageSource }) {

  return (
    <div className='our-service'>
      <div className='image-part'>
        <img src={imageSource} alt='Planck Signal' title='Planck Signal' />
      </div>
      <div className='right-part'>
        <div>
          {header}
        </div>
        <div>
          {content}
        </div>
      </div>
    </div>
  )
}
export default Service;