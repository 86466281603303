import React from 'react'
import { Languages } from '../../../Languages/Languages';

function FooterUpMiddle({ languageId }) {

  const {contact, address, phone } = Languages[languageId]

  return (
    <div className='footer-up-middle'>
      <h3>
        {contact}
      </h3>
      <p>
        <b><i className="fas fa-map-marker-alt"></i> {address}: </b>
        Etimesgut YHT Araç Bakım Atölye Müdürlüğü Ayaş Yolu Bulvarı, Etiler Mah. No:89/C
        <br/>
        Ankara - TURKEY
      </p>
      <p>
        <b><i className="far fa-envelope-open"></i> Email:</b>
        <a href="mailto:info@plancksignal.com">info@plancksignal.com</a>
      </p>

      <p>
        <b><i className="fas fa-phone"></i> {phone}:</b>
        <a href="tel:05546762913">0554 676 2913</a>
        <br/>
        <a href="tel:05545294374">0554 529 4374</a>
      </p>
    </div>
  )
}
export default FooterUpMiddle;