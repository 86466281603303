import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.png'
import TR from '../../assets/Languages/tr.png';
import EN from '../../assets/Languages/en.png';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectLanguageId } from '../../store/selectors';
import { bindActionCreators } from 'redux';
import { setLanguageId } from '../../store/actions';
import { Languages } from '../../Languages/Languages';

function Navbar(props) {

  const onChangeLanguage = async (language) => {
    await props.setLanguageId(language)
  }

  const { homePage, aboutUs, ourServices, career, contact, languages} = Languages[props.languageId];

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img src={Logo} alt="Planck Signal" title='Planck Signal' width="200" />
        </Link>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <form className="d-flex">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/" className="nav-link" activeclassname="active" aria-current="page" >{homePage}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/about-us" className="nav-link" activeclassname="active" >{aboutUs}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/our-services" className="nav-link" activeclassname="active" >{ourServices}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/career" className="nav-link" activeclassname="active" >{career}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/contact" className="nav-link" activeclassname="active" >{contact}</NavLink>
              </li>
              <li className="nav-item dropdown">
                <p className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {languages}
                </p>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <p className="dropdown-item" onClick={() => onChangeLanguage("tr")}>
                      <img src={TR} alt="turkey-flag" title='turkey-flag' />
                      TR
                    </p>
                  </li>
                  <li>
                    <p className="dropdown-item" onClick={() => onChangeLanguage("en")}>
                      <img src={EN} alt="england-flag" title='england-flag' />
                      EN
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </form>

        </div>
      </div>
    </nav>
  )
};

const mapStateToProps = createStructuredSelector({
  languageId: selectLanguageId()
});

const mapDispatchToProps = dispatch => (
  (
    bindActionCreators(
      { setLanguageId },
      dispatch
    )
  )
)

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);