import axios from 'axios';
import React, { Component } from 'react'
import { Languages } from '../../../Languages/Languages';

class FooterUpRight extends Component {
  state = {
    "name": "",
    "surname": "",
    "email": "",
    "subject":"",
    "message": ""
  }

  onChangeValue = e => {

    this.setState({
      [e.target.name]: e.target.value
    });

  }


  onSendMail = e => {
    e.preventDefault();

    const { name, surname, email, subject, message } = this.state;

    axios.post("http://localhost:5000/send-mail/contact-us",{
      name,
      surname,
      email,
      subject,
      message
    }).then(()=>{
      this.setState({
        name:"",
        surname:"",
        subject:"",
        email:"",
        message:""
      })
    })
  }

  render() {

    const { name, surname, subject, email, message } = this.state;
    const { contactUs, nameInput, surnameInput, subjectInput, mailInput, messageInput, sendButton } = Languages[this.props.languageId]

    return (
      <div className='footer-up-right'>
        <h3>
          {contactUs}
        </h3>

        <form onSubmit={this.onSendMail}>
          <div className='input-part-flex'>
            <input type="text" name='name' value={name} onChange={this.onChangeValue} placeholder={nameInput} required />
            <input type="text" name='surname' value={surname} onChange={this.onChangeValue} placeholder={surnameInput} required />
          </div>
          <input type="text" name='subject' value={subject} onChange={this.onChangeValue} className='w-100' placeholder={subjectInput} required />
          <input type="email" name='email' value={email} onChange={this.onChangeValue} className='w-100' placeholder={mailInput} required />
          <textarea className='w-100' name='message' onChange={this.onChangeValue} value={message} placeholder={messageInput} required />
          <div className='w-100 text-end'>
            <button>{sendButton}</button>
          </div>
        </form>
      </div>
    )
  }
}
export default FooterUpRight;