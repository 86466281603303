import React from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Languages } from '../../../Languages/Languages';
import { selectLanguageId } from '../../../store/selectors';
import FooterUpLeft from './FooterUpLeft';
import FooterUpMiddle from './FooterUpMiddle';
import FooterUpRight from './FooterUpRight';

function Footer(props) {
  return (
    <footer>
      <div className='footer-up'>
          <FooterUpLeft languageId={props.languageId} />
          <FooterUpMiddle languageId={props.languageId} />
          <FooterUpRight languageId={props.languageId} />
      </div>

      <div className='footer-down'>
						© 2022 Planck Signal {Languages[props.languageId].footer}
      </div>
    </footer>
  )
};

const mapStateToProps = createStructuredSelector({
  languageId: selectLanguageId()
});

export default connect(mapStateToProps)(Footer);