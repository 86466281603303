import * as constants from './constants';


const initialState = {
    languageId : "tr"
}

export default function store(state = initialState, action){
    const actions = action;

    switch(actions.type){
        case constants.SET_LANGUAGE_ID: {
            return {
                ...state,
                languageId: action.payload
            }
        }

        default:
            return state;
    }
}