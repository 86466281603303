import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PagesTop from '../Common/PagesTop/PagesTop';
import Service from './Service';
import { selectLanguageId } from '../../store/selectors';
import { Languages } from '../../Languages/Languages';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

function OurServices(props) {
  return (
    <>
      {
        props.languageId === "tr" ?
          <Helmet>
            <title>Planck Signal | Hizmetlerimiz</title>
            <meta
              name="description"
              content="Planck Signal | Planck Signal Ekibi olarak projelerimizi görmek ve Planck Signal ekibini tanımak için hemen sitemizi ziyaret edin | Planck Signal"
              data-react-helmet="true"
            />

            <meta name="keywords" content="Planck Signal, Sinyalizasyon" data-react-helmet="true" />
          </Helmet>
          :
          <Helmet>
            <title>Planck Signal | Our Services</title>
            <meta
              name="description"
              content="Planck Signal | As Planck Signal Team, visit our website now to see our projects and to get to know Planck Signal team | Planck Signal"
              data-react-helmet="true"
            />

            <meta name="keywords" content="Planck Signal, Signalling" data-react-helmet="true" />
          </Helmet>
      }
      <motion.div className='our-services'
        initial={{ width: 0 }}
        animate={{ width: "100%" }}
        exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}>
        <PagesTop header={Languages[props.languageId].ourServices} />
        <div className='our-services-body'>
          {
            Languages[props.languageId].sliderServices.slice(0, 3).map(service => (
              <React.Fragment key={service.id}>
                <Service header={service.header} imageSource={service.imageSource} content={service.content} />
              </React.Fragment>
            ))
          }
          {
            Languages[props.languageId].sliderServices.slice(3, Languages[props.languageId].sliderServices.length).map(service => (
              <React.Fragment key={service.id}>
                <Service header={service.header} imageSource={service.imageSource} content={service.content} />
              </React.Fragment>
            ))
          }
        </div>
      </motion.div>
    </>
  )
};

const mapStateToProps = createStructuredSelector({
  languageId: selectLanguageId()
});

export default connect(mapStateToProps)(OurServices);