import React from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectLanguageId } from '../../store/selectors';
import AboutUs from './AboutUs/AboutUs';
import OurReferences from './OurReferences/OurReferences';
import ServicesSlider from './Services/ServicesSlider';
import { Helmet } from "react-helmet";
import { motion } from 'framer-motion';

function HomePage(props) {
  return (
    <>
      {
        props.languageId === "tr" ? 
        <Helmet>
        <title>Planck Signal | Ana Sayfa</title>
        <meta
          name="description"
          content="Planck Signal | Planck Signal Ekibi olarak projelerimizi görmek ve Planck Signal ekibini tanımak için hemen sitemizi ziyaret edin | Planck Signal"
          data-react-helmet="true"
        />

        <meta name="keywords" content="Planck Signal, Sinyalizasyon" data-react-helmet="true" />
      </Helmet>
      : 
      <Helmet>
      <title>Planck Signal | Homepage</title>
      <meta
        name="description"
        content="Planck Signal | As Planck Signal Team, visit our website now to see our projects and to get to know Planck Signal team | Planck Signal"
        data-react-helmet="true"
      />

      <meta name="keywords" content="Planck Signal, Signalling" data-react-helmet="true" />
    </Helmet>
      }

      <motion.div 
      className='homePage'
      initial={{ width: 0 }}
        animate={{ width: "100%"}}
        exit={{ x: window.innerWidth, transition: { duration: 0.2}  }}
      >
        <ServicesSlider languageId={props.languageId} />
        <AboutUs languageId={props.languageId} />
        <OurReferences />
      </motion.div>
    </>
  )
};

const mapStateToProps = createStructuredSelector({
  languageId: selectLanguageId()
});

export default connect(mapStateToProps)(HomePage);