import { imageSources } from "./ImageSources"

export const Languages = {
    "en": {
        "homePage": "Homepage",
        "aboutUs": "About Us",
        "ourServices": "Our Services",
        "career": "Career",
        "contact": "Contact",
        "languages": "Languages",
        "sliderServices": [
            {
                id: 0,
                header: "Inspection and Malfunction Diagnosis",
                content: "Malfunction diagnosis occurring in ETCS Onboard systems in locomotives and train sets.",
                imageSource: "/static/media/maintenances.8e35664bd0fe2e83331c.png",
            },
            {
                id: 1,
                header: "Black box record analyses",
                content: "Interpretation and analysis of juridical recording units (JRU) of ETCS Onboard equipment.",
                imageSource: imageSources.blackBox,
            },
            {
                id: 2,
                header: "Malfuntion Correction",
                content: "Correction of ETCS malfunctions in Train Sets and  Locomotives.",
                imageSource: imageSources.fault,
            },
            {
                id: 3,
                header: "Training Services",
                content: "Comprehensive trainings regarding the operation and use of onboard signalling systems.",
                imageSource: imageSources.training,
            },
            {
                id: 4,
                header: "Installation-Comissioning",
                content: "ERTMS Onboard equipments Installation and commissioning services.",
                imageSource: imageSources.fault,
            }
        ],
        "aboutUsHeader": "Who Are We?",
        "aboutUsShortExplaining": "About Us",
        "aboutUsParts": [
            {
                id:0,
                name: "About Us",
                content:"about us",
                background:"#a346a7",
                iconClass:"fas fa-building"
            },
            {
                id:1,
                name:"Vision",
                content:"vision",
                background:"#fec933",
                iconClass:"fas fa-eye"
            },
            {
                id:2,
                name:"Mission",
                content:"mission",
                background:"#f36b37",
                iconClass:"fas fa-rocket"
            },{
                id:3,
                name:"Our Values",
                content:"our values",
                background:"#81b441",
                iconClass:"fas fa-check"
            }
        ],
        "more": "more",
        "pages": "Pages",
        "address": "Address",
        "phone": "Phone*",
        "subject":"Subject",
        "contactUs": "Contact Us",
        "nameInput": "Name*",
        "surnameInput": "Surname*",
        "mailInput": "E-mail*",
        "subjectInput":"Subject*",
        "gender":"Gender",
        "man":"Man",
        "woman":"Woman",
        "messageInput": "Your Message",
        "sendButton": "Send",
        "mainAddressContactHeader":"MAIN ADDRESS",
        "phoneNumberContactHeader":"PHONE NUMBER",
        "mailContactHeader":"E-MAIL",
        "socialMediaContactHeader":"SOCIAL MEDIA",
        "contactUsHeader":"Contact Us",
        "footer": "All Rights Reserved"
    },

    "tr": {
        "homePage": "Ana Sayfa",
        "aboutUs": "Hakkımızda",
        "ourServices": "Hizmetlerimiz",
        "career": "Kariyer",
        "contact": "İletişim",
        "languages": "Diller",
        "sliderServices": [
            {
                id: 0,
                header: "Muayene ve Arıza Teşhisi",
                content: "Lokomotiflerde ve tren setlerindeki ETCS araç üstü sistemlerde oluşan arıza teşhisleri.",
                imageSource: "/static/media/maintenances.8e35664bd0fe2e83331c.png",
            },
            {
                id: 1,
                header: "Kara Kutu Kayıt Analizleri",
                content: "ETCS araç üstü ekipmanlarının hukuki kayıt birimlerinin kayıtlarının anlamlandırılması ve analiz edilmesi.",
                imageSource: imageSources.blackBox,
            },
            {
                id: 2,
                header: "Arıza Islahı",
                content: "Tren setlerindeki ve lokomotiflerdeki ETCS arızalarının ıslahı.",
                imageSource: imageSources.fault,
            },
            {
                id: 3,
                header: "Eğitim Hizmetleri",
                content: "Araç üstü sinyalizasyon sistemlerinin işleyişi, ve kullanımı ile ilgili kapsamlı eğitimler.",
                imageSource: imageSources.training,
            },
            {
                id: 4,
                header: "Kurulum - Devreye alma",
                content: "ERTMS Araç üstü ekipman kurulum ve devreye alma hizmetleri.",
                imageSource: imageSources.fault,
            }
        ],
        "aboutUsHeader": "Biz Kimiz?",
        "aboutUsShortExplaining": "Planck Sinyalizasyon Mühendislik ve Danışmanlık Hizmetleri Limited Şirketi, demiryolu sektöründe sinyalizasyon, elektronik, elektromekanik ve mekanik alanlarda  hizmet veren yerli bir firmadır.",
        "aboutUsParts": [
            {
                id:0,
                name:"Hakkımızda",
                content:"Planck Sinyalizasyon Mühendislik ve Danışmanlık Hizmetleri Limited Şirketi, demiryolu sektöründe sinyalizasyon, elektronik, elektromekanik ve mekanik alanlarda  hizmet veren yerli bir firmadır.",
                background:"#a346a7",
                iconClass:"fas fa-building"
            },
            {
                id:1,
                name:"Vizyon",
                content:"Demiryolu mühendislik uygulamalarının daha yüksek standartlara ulaşmasını sağlayarak, küresel pazardaki mühendislik ihtiyacını yüksek kalitede ve üretim seviyesinde karşılayabilmek.",
                background:"#fec933",
                iconClass:"fas fa-eye"
            },
            {
                id:2,
                name:"Misyon",
                content:"Demiryolu Sinyalizasyon ve Elektronik/Elektromekanik teknolojileri ve sistem entegrasyonu alanında; yurt içi ve yurt dışındaki müşterilerine yüksek katma değerli, yenilikçi, güvenilir hizmet ve çözümler sunmak. Ülkemizin demiryolu alanında dışa bağımlılığını azaltıp, küresel hedeflerine marka bilinirliğini artırarak ulaşan bir mühendislik şirketi olmak.",
                background:"#f36b37",
                iconClass:"fas fa-rocket"
            },{
                id:3,
                name:"Değerlerimiz",
                content:"-",
                background:"#81b441",
                iconClass:"fas fa-check"
            }
        ],
        "more": "daha fazla",
        "pages": "Sayfalar",
        "address": "Adres",
        "phone": "Telefon*",
        "subject":"Konu",
        "contactUs": "Bize Ulaşın",
        "nameInput": "Adınız*",
        "surnameInput": "Soyadınız*",
        "mailInput": "E-posta*",
        "subjectInput":"Konu*",
        "gender":"Cinsiyetiniz",
        "man":"Erkek",
        "woman":"Kadın",
        "messageInput": "Mesajınız",
        "sendButton": "Gönder",
        "mainAddressContactHeader":"ANA ADRESİMİZ",
        "phoneNumberContactHeader":"TELEFON NUMARASI",
        "mailContactHeader":"E-POSTA",
        "socialMediaContactHeader":"SOSYAL MEDYA",
        "contactUsHeader":"Bizimle İletişime Geçin",
        "footer": "Tüm Hakları Saklıdır"
    }
}

