import React from 'react'
import { Link } from 'react-router-dom';
import { Languages } from '../../../Languages/Languages';

function FooterUpLeft({ languageId }) {

    const { pages, homePage, ourServices, aboutUs, career, contact} = Languages[languageId]

    return (
        <div className='footer-up-left'>
            <h3>
                {pages}
            </h3>
            <Link to="/">
                {homePage}
            </Link>
            <Link to="/about-us">
                {aboutUs}
            </Link>
            <Link to="/our-services">
                {ourServices}
            </Link>
            <Link to="/career">
                {career}
            </Link>
            <Link to="/contact">
                {contact}
            </Link>
        </div>
    )
}
export default FooterUpLeft;